import React from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

const slideImages = [
  {
    url: "https://friendtok.com/upload/photos/2022/10/4LKcDBxxbhfmcTjwLkXP_18_4fadd4d24bbeaabba0c5facde62c958d_image.jpg",
    caption: "Slide 1",
  },
  {
    url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTBPYZ4Ckfzp6iBv2SfKoz30BuI-YOjUFErilIA5-J4Kkg4OrShzEy5GKXGjCzOUIBZ7_w&usqp=CAU",
    caption: "Slide 2",
  },
  {
    url: "https://pbs.twimg.com/media/EYUUv2CWoAA7Tbk.jpg",
    caption: "Slide 3",
  },
  {
    url: "https://pbs.twimg.com/media/EYYw2NkU8AM30-J.jpg",
    caption: "Slide 3",
  },
  {
    url: "https://pbs.twimg.com/media/EYcoJgVXQAIY_vx.jpg",
    caption: "Slide 3",
  },
  {
    url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRAlsJS6Wh1E8-4HGHzGrG-jRQKpE7_IrAwe8txM0nDmQggSTQ9mW2l5VVxDobmv6kMLzw&usqp=CAU",
    caption: "Slide 3",
  },
  {
    url: "https://pbs.twimg.com/media/FJO3rweX0Ac1T_O.jpg",
    caption: "Slide 3",
  },
];
function Header() {
  return (
    <header>
      <div className="container">
        <div className="slide-container">
          <Slide autoplay="true" arrows="false" infinite="true">
            {slideImages.map((slideImage, index) => (
              <div class="col py-2 text-center">
                <img
                  class="d-block mx-auto"
                  src={slideImage.url}
                  alt="memes"
                  width="300"
                  height="250"
                />
              </div>
            ))}
          </Slide>
        </div>
      </div>
    </header>
  );
}

export default Header;
