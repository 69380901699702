// This code uses the useSelector hook from the react-redux
// library to access the "change_language" state from the
// store and also it uses the useParams hook from the react-router-dom library
//  to access the dynamic parameters from the URL.

import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { templates } from "../data/data";
import Headers from "./Headers";
import { useSelector } from "react-redux";
import { baseURL } from "./../resources/apiClient";
import axios from "axios";
import LoadingSpinner from "./SpinnerLoader";
import { useQuery, queryCache } from "react-query";
import swal from "sweetalert";
import http from "./../resources/http";
import AdminHeader from "./AdminHeader";
import { toast } from "react-toastify";
import AdminRoute from "../auth/AdminRoute";

const fetchData = async () => {
  const { data } = await axios.get(`${baseURL}meme/meme-url`);
  return data;
};

const AdminTemplates = () => {
  let localStorageData = JSON.parse(localStorage.getItem("meme"));
  const isAdmin = localStorageData?.user?.user_type;
  const { id } = useParams(); // useParams hook is used to access the dynamic parameters from the URL in react-router-dom
  const { data, isLoading, error, refetch } = useQuery(
    "memeTemplates",
    fetchData
  );
  const navigate = useNavigate();

  console.log(id, "param id");

  const [memes, setMemes] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    refetch();
  }, []);
  const deleteData = async (id) => {
    try {
      await Promise.all([
        http.delete(`${baseURL}meme/templates/${id}`),
        http.delete(`${baseURL}meme/meme-url/${id}`),
      ]);
      swal("Template deleted successfully!");
      refetch();
    } catch (error) {
      console.error(error);
      swal("An error occurred while deleting the template");
    }
  };
  //  const response = await axios.post(`${baseURL}meme/meme-url`, formData, {

  console.log(memes, "memes");
  return (
    <div>
      {isAdmin === "ADMIN" ? (
        <div className="h-screen overflow-y-auto">
          <AdminHeader />
          {!isLoading ? (
            <div class="grid sx:grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 gap-4  items-center justify-center ">
              {data?.map((memes) => {
                return (
                  <div
                    key={memes.id}
                    class="p-1 cursor-pointer  flex flex-col  rounded-xl group sm:flex space-x-6 bg-white bg-opacity-50 shadow-xl hover:rounded-2xl"
                  >
                    <div className="pb-2">
                      <img
                        onClick={() => {
                          navigate(`/meme-maker/${memes.id}`);
                          refetch();
                        }}
                        className="img-fluid w-full img-thumbnail object-cover flex-1"
                        alt="art cover"
                        loading="lazy"
                        src={memes.img}
                      />
                    </div>
                    {isAdmin === "ADMIN" ? (
                      <button
                        type="button"
                        onClick={() => deleteData(memes.id)}
                        className="flex mx-16 w-36 py-2  mt-10 bg-red-600 text-white button-signup justify-center items-center "
                      >
                        Delete
                      </button>
                    ) : null}
                  </div>
                );
              })}
            </div>
          ) : (
            <LoadingSpinner />
          )}
        </div>
      ) : (
        <AdminRoute />
      )}
    </div>
  );
};

export default AdminTemplates;
