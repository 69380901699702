import React, { useState } from "react";
import { Resizable } from "react-resizable";
import Draggable from "react-draggable";

const Profile = () => {
  const [width, setWidth] = useState(300);
  const [height, setHeight] = useState(300);
  const [onResize, setOnResize] = useState(false);

  const handleResize = (event, { size }) => {
    setWidth(size.width);
    setHeight(size.height);
  };

  const handleStart = (event, data) => {
    if (onResize) {
      event.stopPropagation();
    }
  };

  return (
    <div style={{ position: "relative", width: "100%", height: "100%" }}>
      <Resizable
        width={width}
        height={height}
        onResizeStart={() => setOnResize(true)}
        onResizeStop={() => setOnResize(false)}
        onResize={handleResize}
        style={{
          position: "absolute",
          bottom: 0,
          right: 0,
          border: "1px solid #ddd",
          background: "rgba(255, 255, 255, 0.8)",
        }}
        minConstraints={[100, 100]}
        maxConstraints={[500, 500]}
      >
        <Draggable
          onStart={handleStart}
          bounds="parent"
          position={{ x: 0, y: 0 }}
        >
          <img
            src="https://source.unsplash.com/random/400x400"
            alt="Random"
            style={{ width: "100%", height: "100%" }}
          />
        </Draggable>
      </Resizable>
    </div>
  );
};

export default Profile;
