import axios from "axios";
import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import { baseURL } from "./../resources/apiClient";
import AdminHeader from "./AdminHeader";
import { queryCache } from "react-query";
import { useNavigate } from "react-router-dom";
import AdminRoute from "../auth/AdminRoute";
const AddTemplates = () => {
  const [loading, setLoading] = useState(false);
  const [img, setImg] = useState(null);
  const [img2, setImg2] = useState(null);
  const [imgUrl, setImgUrl] = useState(null);
  const [imgUrl2, setImgUrl2] = useState(null);
  const [base64Image, setBase64Value] = useState("");
  const [templates, setTemplates] = useState([]);
  const [base64, setBase64] = useState([]);
  const token = JSON.parse(localStorage.getItem("meme"));
  const isAdmin = token?.user.user_type; //true
  const navigate = useNavigate();
  console.log(isAdmin, "are you admin");
  var base64code = "";
  const onChange = (e) => {
    const files = e.target.files;
    const file = files[0];
    getBase64(file);
  };

  const onLoad = (fileString) => {
    base64code = fileString;
    console.log("base64 Teshie0", fileString);
    setBase64Value(base64code);
  };
  const getBase64 = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      onLoad(reader.result);
    };
  };
  const handleFileUpload = (e) => {
    setImg(e.target.files[0]);
  };

  const handleFileUpload2 = (e) => {
    setImg2(e.target.files[0]);
    const files = e.target.files;
    const file = files[0];
    getBase64(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("img", img);
    formData.append("img2", img2);

    try {
      const res = await axios.post(`${baseURL}meme/meme-url`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      axios
        .post(`${baseURL}meme/templates`, {
          title: "Title",
          imgUrl: base64Image,
        })
        .then((response) => {
          swal("Success");
        })
        .catch((error) => {
          swal("No Success");
        });
      setLoading(false);
      console.log(res, "response");

      setImgUrl(res.data.img);
      setImgUrl2(res.data.img2);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };
  useEffect(() => {
    axios
      .get(`${baseURL}meme/templates`)
      .then((response) => {
        console.log(response.data, "another");
      })
      .catch((error) => {
        swal("No Success");
      });
  }, []);

  return (
    <div>
      {isAdmin === "ADMIN" ? (
        <div>
          <AdminHeader />
          <div className="grid place-items-center h-screen  border ">
            <div className="flex flex-col space-y-10 border-4 p-3">
              {/* create input fields with name, description, image, price, quantity */}
              <p className="text-3xl">Select Meme Images</p>

              <div>
                <input
                  onChange={handleFileUpload}
                  type="file"
                  placeholder="Name"
                  class=" border border-sky-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1 "
                />
              </div>
              <p className="text-3xl">Select Template Images</p>

              <div>
                <input
                  onChange={handleFileUpload2}
                  type="file"
                  placeholder="Name"
                  class=" border border-sky-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1 "
                />
              </div>
              <div className="bg-blue-300 rounded flex justify-center items-center">
                {loading ? (
                  <div class="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                ) : null}
              </div>
              {!loading ? (
                <div className=" flex justify-center items-center p-4 w-full">
                  <button
                    onClick={(e) => handleSubmit(e)}
                    className="bg-sky-500 py-2 text-white px-4 flex"
                  >
                    <p>Submit</p>
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      ) : (
        // <div>
        //   <span>Unouthorized acces</span>
        // </div>
        <AdminRoute />
      )}
    </div>
  );
};

export default AddTemplates;
