import React, { useState } from "react";
import Header from "./SlideShow";
import Draggable from "react-draggable";
import "../index.css";

import Headers from "./Headers";
import { useSelector } from "react-redux";
import swal from "sweetalert";

function MemeDisplay(props) {
  const [users, setUsers] = useState([
    {
      firstName: "", // state hook to store the first name
      rotation: 0, // state hook to store the rotation
      fontSize: 14, // state hook to store the font size
      fontWeight: "bolder", // state hook to store the font weight
      color: "#000000", // state hook to store the color
    },
  ]);

  // function to add a new user
  function addClick() {
    setUsers((prevState) => [
      ...prevState,
      { firstName: "", fontSize: 14, fontWeight: "bolder", color: "#000000" }, // resetting the state for the new user
    ]);
  }

  // function to reset the users
  function resetClick() {
    swal({
      // using sweetalert to show the reset confirmation message
      title: "Are you sure?",
      text: "This will reset all the text input changes including font size, color, and font weight.",
      icon: "warning",
      buttons: ["No, keep it", "Yes, reset it!"],
    }).then((result) => {
      if (result === true) {
        setUsers([
          {
            firstName: "", // resetting the first name
            fontSize: 14, // resetting the font size
            fontWeight: "bolder", // resetting the font weight
            color: "#000000", // resetting the color
          },
        ]);
      }
    });
  }

  function createUI() {
    //a function to add dynamic input boxes
    const fontSizes = [
      12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40, 42, 44, 46,
      48, 50, 52, 54, 56, 58, 60, 62, 64, 66, 68, 70, 72,
    ]; // array of font sizes
    const fontWeights = ["normal", "bold", "bolder", "lighter"]; // array of font weights

    return users.map((el, i) => (
      <div key={i} className="flex space-x-4 border mb-2 rounded ">
        <input
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          name="firstName"
          value={el.firstName || ""}
          onChange={(e) => handleChanges(e, i)}
        />
        <div className="flex justify-center items-center space-x-2">
          <div className="flex justify-center space-x-3 items-center border rounded ">
            {/* <label>Font Size</label> */}
            <select
              className="border border-primary rounded"
              name="fontSize"
              value={el.fontSize || 28}
              onChange={(e) => handleChanges(e, i)}
            >
              {fontSizes.map((size) => (
                <option value={size}>{size}</option>
              ))}
            </select>
          </div>
          <div className="flex justify-center  items-center border rounded ">
            {/* <label>Font Weight</label> */}
            <select
              className="border border-primary rounded"
              name="fontWeight"
              value={el.fontWeight || "bolder"}
              onChange={(e) => handleChanges(e, i)}
            >
              {fontWeights.map((weight) => (
                <option value={weight}>{weight}</option>
              ))}
            </select>
          </div>
          <div className="flex justify-center space-x-3 items-center border rounded ">
            {/* <label>Font Color</label> */}
            <input
              type="color"
              name="color"
              value={el.color || "#000000"}
              onChange={(e) => handleChanges(e, i)}
              className="hide-color-picker-icon border border-primary  py-1 rounded-lg shadow-md focus:outline-none focus:shadow-outline-blue-500"
            />
          </div>

          <button
            type="button"
            onClick={() => removeClick(i)}
            className="px-2 py-1 rounded-lg shadow-md focus:outline-none focus:shadow-outline-blue-500 bg-red-500 hover:bg-red-700 text-white"
          >
            X
          </button>
        </div>
      </div>
    ));
  }

  function handleChanges(e, i) {
    const { name, value } = e.target;
    let updatedUsers = [...users];
    updatedUsers[i] = { ...updatedUsers[i], [name]: value };
    setUsers(updatedUsers);
  }

  function removeClick(i) {
    let updatedUsers = [...users];
    updatedUsers.splice(i, 1);
    setUsers(updatedUsers);
  }

  function handleSubmit(event) {
    alert("A name was submitted: " + JSON.stringify(users));
    event.preventDefault();
  }

  ///
  const [rotation, setRotation] = useState(0);
  const [topText, setTopText] = useState("");
  const [bottomText, setBottomText] = useState("");
  const data = useSelector((state) => state.change_language);

  function handleDrag(e, data) {
    setRotation(data.rotation);
  }

  const [fontSize, setFontSize] = React.useState(16);
  const [fontSizeBottom, setFontSizeBottom] = React.useState(16);

  //Handle canvas
  const [canvasPosition, setCanvasPosition] = useState("none");

  const handleChangeCanvas = (event) => {
    setCanvasPosition(event.target.value);
  };

  let canvasClasses = "";
  if (canvasPosition === "top") {
    canvasClasses = "pt-12";
  } else if (canvasPosition === "bottom") {
    canvasClasses = "pb-12";
  } else if (canvasPosition === "both") {
    canvasClasses = "pt-12 pb-12";
  }

  const [rotated, setRotated] = useState(false);

  // function to rotate the div element
  const rotate = () => {
    setRotated((rotated) => !rotated);
  };

  // const watermarkText = useSelector((state) => state.water_mark);
  const [watermarkText, setWatermarkText] = useState("");

  function changeState() {
    setWatermarkText("By Enable ET");
  }
  function clearState() {
    setWatermarkText("");
  }

  return (
    <div className="mt-10 bg-blue-900">
      <Headers
        topText={users}
        changeState={changeState}
        clearState={clearState}
      />
      {/* <DynamicDraggable /> */}
      <div className="p-2">
        <div className="rounded p-2 flex justify-around w-1/3 bg-orange-600  mb-2 m-10 mt-10 ">
          <div className="flex justify-center items-center space-x-4">
            <span className="text-white font-bold">Spacing</span>
            <select value={canvasPosition} onChange={handleChangeCanvas}>
              <option value="none">None</option>
              <option value="top">Top</option>
              <option value="bottom">Bottom</option>
              <option value="both">Both</option>
            </select>
          </div>

          <div className="flex justify-center items-center space-x-4">
            <span className="text-white font-bold">Rotate Image</span>
            <button
              className="bg-blue-500 text-white p-2 rounded"
              onClick={rotate}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                />
              </svg>
            </button>
          </div>
        </div>

        <div className="grid sx:grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-4">
          <div className="mt-4">
            <div className="container">
              <div className="row ">
                <div className="col-md-12">
                  <div className="  overflow-hidden mb-4">
                    <div className="col-lg-16 p-2 ">
                      <div
                        id="my-meme"
                        style={{ transform: `rotate(${rotated ? 180 : 0}deg)` }}
                        className="z-30 vnicornMeme relative"
                      >
                        <div
                          className={`bg-white ${canvasClasses} flex flex-col justify-center items-center text-center`}
                        >
                          {users.map((user, index) => (
                            <Draggable key={index}>
                              <span
                                className="absolute top-16"
                                style={{
                                  fontSize: `${user.fontSize}px`,
                                  fontWeight: user.fontWeight,
                                  color: user.color,
                                  transform: `rotate(${user.rotation}deg)`,
                                }}
                              >
                                {user.firstName}
                              </span>
                            </Draggable>
                          ))}
                          <Draggable onDrag={handleDrag} className="">
                            <h2
                              className="text-black  cursor-pointer absolute top-0 text-center"
                              style={{
                                cursor: "pointer",
                                fontSize: `${fontSize}px`,
                                fontWeight: "bold",
                              }}
                            >
                              {topText}
                            </h2>
                          </Draggable>

                          <img
                            src={props.data.randomImg}
                            className="img-fluid img-thumbnail  object-cover"
                            alt=""
                            width="100%"
                          />

                          <Draggable onDrag={handleDrag} className="">
                            <h2
                              className="text-wrap text-black  cursor-pointer absolute bottom-4 text-center"
                              style={{
                                cursor: "pointer",
                                fontSize: `${fontSizeBottom}px`,
                                fontWeight: "bold",
                              }}
                            >
                              {bottomText}
                            </h2>
                          </Draggable>
                        </div>
                        <div className="">
                          <Draggable onDrag={handleDrag} className="">
                            <h2
                              className="author mx-3 text flex-start items-start text-wrap   cursor-pointer absolute bottom-16 text-center"
                              style={{ cursor: "pointer" }}
                            >
                              {props.data.authorText.length > 0 && (
                                <> @ {props.data.authorText} </>
                              )}
                            </h2>
                          </Draggable>
                        </div>
                        <div className="">
                          <h2
                            className="author  text flex-start items-start   cursor-pointer absolute top-16 p-2 text-center"
                            style={{ cursor: "pointer" }}
                          >
                            {" "}
                            {watermarkText}
                          </h2>
                        </div>

                        {/* <Draggable onDrag={handleDrag} className="">
                        <h2
                          className="text-black top-0 cursor-pointer"
                          style={{ transform: `rotate(${rotation}deg)` }}
                        >
                          {topText}
                        </h2>
                      </Draggable> */}
                        {/* <Draggable onDrag={handleDrag}>
                        <h2
                          className="bottomText cursor-pointer "
                          style={{ transform: `rotate(${rotation}deg)` }}
                        >
                          {bottomText}
                        </h2>
                      </Draggable> */}
                      </div>
                      {/* <div className="z-50 flex flex-col space-y-10 shadow-lg p-3 mb-3 bg-white rounded">
                        {users[0].firstName?.length > 1 ? (
                          <div className="input-group w-36 text-white border">
                            <div className="bg-white w-full custom-file flex justify-around items-center">
                              <label
                                className="custom-file-label font-semibold text-xl border-info text-black"
                                for="inputGroupFile04"
                              >
                                Share to Telegram
                              </label>
                              <TelegramShareButton url={"hello"}>
                                <TelegramIcon size={60} round={true} />
                              </TelegramShareButton>
                            </div>
                          </div>
                        ) : null}
                      </div> */}
                    </div>
                  </div>
                  {/* <Footer /> */}

                  {/* Works!! */}
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1 z-50 mt-4  p-2 d-flex flex-column position-static">
            <div className="hidden  lg:block shadow-lg p-3 mb-3 bg-white rounded">
              <Header />
            </div>
            <div className="shadow-lg p-3 mb-3 bg-white rounded">
              <p className="text-muted">{data.details}</p>
              <form onSubmit={handleSubmit} className="flex flex-col space-y-2">
                {createUI()}
                <input
                  className="sr-only form-control border-info"
                  type="text"
                  name="authorText"
                  placeholder={data.author}
                  value={props.data.authorText}
                  onChange={props.handleChange}
                />
                <div className="flex justify-between items-center">
                  <input
                    className="bg-green-600 text-white px-2 py-1 rounded"
                    type="button"
                    value="+ Add more"
                    onClick={addClick}
                  />
                  <label
                    htmlFor="image-upload"
                    className="bg-blue-500 py-2 font-bold rounded px-2 text-white cursor-pointer flex items-center justify-center"
                  >
                    <svg
                      className="w-6 h-6 mr-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                    </svg>
                    <span>Choose a file</span>
                    <input
                      type="file"
                      id="image-upload"
                      className="hidden"
                      onChange={props.handleUpload}
                    />
                  </label>
                  <input
                    className="bg-red-600 text-white px-2 py-1 rounded"
                    type="button"
                    value="Reset All"
                    onClick={resetClick}
                  />
                </div>
              </form>

              {/* <div className="flex space-x-2">
                <textarea
                  className="form-control border-info flex-1"
                  rows="1"
                  cols="2"
                  name="bottomText"
                  placeholder={data.topText}
                  value={topText}
                  onChange={(e) => setTopText(e.target.value)}
                  id="text"
                ></textarea>
                <div>
                  <span>Font Size</span>
                  <select
                    className="text-red-900"
                    onChange={handleChange}
                    value={fontSize}
                  >
                    <option value={16}>Small</option>
                    <option value={26}>Medium</option>
                    <option value={36}>Large</option>
                  </select>
                </div>
              </div>
              <div className="flex space-x-2 mt-2 mb-2 ">
                <textarea
                  className="form-control border-info flex-1"
                  rows="1"
                  cols="2"
                  name="bottomText"
                  placeholder={data.bottomText}
                  value={bottomText}
                  onChange={(event) => setBottomText(event.target.value)}
                  id="text"
                ></textarea>
                <div>
                  <span>Font Size</span>
                  <select
                    className="text-red-900"
                    onChange={handleChangeBottom}
                    value={fontSizeBottom}
                  >
                    <option value={16}>Small</option>
                    <option value={26}>Medium</option>
                    <option value={36}>Large</option>
                  </select>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MemeDisplay;
