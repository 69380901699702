import React from "react";

const MemeLoaderAnimation = () => {
  return (
    <div className="flex justify-center h-screen items-center">
      <div class="lds-facebook">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default MemeLoaderAnimation;
