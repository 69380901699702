// The code provided is a collection of various React functional components that utilize hooks
// such as useState, useEffect, useSelector, and useDispatch.
//  These components use the state, effect, and dispatch hooks to handle user inputs, data,
//  and actions respectively. Some of the code use external library
//   such as axios to make HTTP request, swal for sweetalert, react-router-dom
//    to navigate between different routes. The code also uses the window.open()
//    to open the SMS app with a predefined phone number and message, also it has
//    some code that check the width of the screen to check if the device is mobile
//    or not. And there is code that use htmlToImage library to download PNG image
//    and send it to the server. The code also uses the localStorage to remove an
//    item. Overall, the code is a collection of various functionalities that are
//    commonly used in React web development such as state management, data fetching,
//    routing, and form handling.

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { baseURL } from "../resources/apiClient";
import http from "../resources/http";
import Headers from "./Headers";
import LoadingSpinner from "./SpinnerLoader";

const MyMemes = () => {
  const [meme, setMeme] = useState([]);
  const user = useSelector((state) => state.user); // get the user data from the redux store
  console.log(user, "user data");
  const [loading, setLoading] = useState(false); // set loading state to false

  const fetchData = () => {
    setLoading(true); // set loading state to true before fetching data
    http
      .get(`${baseURL}meme/myMeme`)
      .then((response) => {
        setMeme(response.data);
        setLoading(false); // set loading state to false after data is fetched
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    fetchData();
  }, []); // call the fetchData function on component mount

  const displayMemes = meme.map((memes) => {
    return (
      <div class="p-1  flex flex-col  rounded-xl group sm:flex space-x-6 bg-white bg-opacity-50 shadow-xl hover:rounded-2xl">
        <div className="pb-2">
          <img
            class="mx-auto w-full h-96 block   rounded-lg"
            alt="art cover"
            loading="lazy"
            src={memes.img}
          />
        </div>
      </div>
    );
  });

  console.log(meme);
  return (
    <div>
      <Headers />
      <div className="p-4 bg-slate-200 h-screen overflow-y-auto">
        {!loading ? null : <LoadingSpinner />}
        <div class="grid sx:grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 gap-4  items-center justify-center min-h-screen">
          {meme?.length ? (
            displayMemes
          ) : (
            <div className="  p-16">
              <Link
                className="font-bold text-3xl mr-10 text-blue-500"
                to="/meme-maker"
              ></Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyMemes;
