// This code is a React functional component that defines the routes and components for the application.
//  It uses the react-router-dom library to render different components depending on the URL path.

// The routes include:

// "/" which renders the Login component
// "/signup" which renders the Signup component
// "/meme-maker" which renders the MainGenerator component
// "/meme-maker/:id" which renders the MemeGeneratorFromTemplates component
// "/gallery" which renders the MemeDisplayAll component
// "/myMeme" which renders the MyMemes component
// "/templates" which renders the Templates component
// "/profile" which renders the Profile component
// It uses the <Routes> and <Route> elements from react-router-dom to define the routing logic.
// The path attribute of the <Route> element specifies the URL path that the component should be rendered for,
//  and the element attribute specifies the component to be rendered.

import React from "react";
import MainGenerator from "./memeComponents/MainGenerator";
import Login from "./auth/Login";
import Signup from "./auth/Signup";
import { Routes, Route } from "react-router-dom";
import MyMemes from "./memeComponents/MyMemes";
import Templates from "./memeComponents/Templates";
import MemeGeneratorFromTemplates from "./memeComponents/MemeGeneratorFromTemplates";
import MemeDisplayAll from "./memeComponents/MemeDiaplayAll";
import Profile from "./memeComponents/Profile";
import { RequireAuth } from "./auth/AuthManager";
import AddTemplates from "./memeComponents/AddTemplates";
import FAQs from "./memeComponents/FAQs";
import { QueryClient, QueryClientProvider } from "react-query";
import AdminTemplates from "./memeComponents/AdminTemplates";
import AddUser from "./memeComponents/AddUser";
import ManageUsers from "./memeComponents/ManageUsers";
import AdminHeader from "./memeComponents/AdminHeader";
import ImageUpload from "./memeComponents/ImageUpload";
import { ADMIN } from "./utils/constants";
import AdminRoute from "./auth/AdminRoute";
import UploadGallery from "./memeComponents/UploadGallery";
import AdminGallery from "./memeComponents/AdminGallery";
function App() {
  let queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <div className=" h-screen">
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route
            path="/meme-maker"
            element={
              <RequireAuth>
                <MainGenerator />
              </RequireAuth>
            }
          />
          <Route
            path="/faqs"
            element={
              <RequireAuth>
                <FAQs />
              </RequireAuth>
            }
          />
          <Route
            path="/admin-templates"
            element={
              <RequireAuth>
                <AdminTemplates />
              </RequireAuth>
            }
          />
          <Route
            path="/manage-users"
            element={
              <RequireAuth>
                <AdminHeader />
                <ManageUsers />
              </RequireAuth>
            }
          />
          <Route
            path="/admin"
            element={
              <RequireAuth>
                <AddTemplates />
              </RequireAuth>
            }
          />
          <Route
            path="/upload-gallery"
            element={
              <RequireAuth>
                <UploadGallery />
              </RequireAuth>
            }
          />
          <Route
            path="/meme-maker/:id"
            element={
              <RequireAuth>
                <MemeGeneratorFromTemplates />
              </RequireAuth>
            }
          />
          <Route
            path="/gallery"
            element={
              <RequireAuth>
                <MemeDisplayAll />
              </RequireAuth>
            }
          />
          <Route
            path="/admin-gallery"
            element={
              <RequireAuth>
                <AdminGallery />
              </RequireAuth>
            }
          />
          <Route
            path="/myMeme"
            element={
              <RequireAuth>
                <MyMemes />
              </RequireAuth>
            }
          />
          <Route
            path="/templates"
            element={
              <RequireAuth>
                <Templates />
              </RequireAuth>
            }
          />
          <Route
            path="/profile"
            element={
              <RequireAuth>
                <Profile />
              </RequireAuth>
            }
          />
        </Routes>
      </div>
    </QueryClientProvider>
  );
}

export default App;
