// This code is using several React Hooks, it is using useState to manage the component's state and handle actions such as toggling the loading state. it also uses useSelector hook from react-redux library to access the data from the store, it logs the data to the console.
// It uses axios to make a GET request to the endpoint ${baseURL}meme/memes to fetch the data, and then it updates the state with the fetched data.
// The useEffect hook is being used to call the fetchData function when the component is mounted to the DOM.
// It is also worth noting that it's using the console.log() function to log the data to the browser's

import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { baseURL } from "../resources/apiClient";
import http from "../resources/http";
import Headers from "./Headers";
import LoadingSpinner from "./SpinnerLoader";

const MemeDisplayAll = () => {
  const [meme, setMeme] = useState([]); // state hook to store the fetched memes
  const data = useSelector((state) => state.change_language); // hook from react-redux to access the data from the store
  const [loading, setLoading] = useState(false); // state hook to control the loading state
  console.log(data, "languages"); // logging the data to the console

  let localStorageData = JSON.parse(localStorage.getItem("meme"));
  const isAdmin = localStorageData?.user?.user_type;

  // function to fetch data from the server
  const fetchData = () => {
    setLoading(true); // setting loading state to true
    axios
      .get(`${baseURL}meme/memes`) // making a GET request to the endpoint
      .then((response) => {
        setMeme(response.data); // updating the state with the fetched data
        setLoading(false); // setting loading state to false
      })
      .catch((error) => console.log(error)); // logging any error to the console
  };

  useEffect(() => {
    fetchData(); // calling the fetchData function on component mount
  }, []);

  const deleteData = (id) => {
    // http
    //   .delete(`${baseURL}meme/templates/${id}`)
    //   .then((response) => {
    //     swal("Success!", "Template deleted Successfully!", "success");
    //     refetch();
    //   })
    //   .catch((err) => {
    //     alert(err);
    //   });
    http
      .delete(`${baseURL}meme/memes/${id}`)
      .then((response) => {
        swal("Success!", "Meme deleted Successfully!", "success");
        window.location.reload();
      })
      .catch((err) => {
        alert(err);
      });
  };
  console.log(meme, "memes");
  return (
    <div>
      <Headers />
      <div className="p-4 bg-gray-200 h-screen overflow-y-auto ">
        {!loading ? null : <LoadingSpinner />}
        <div class="grid sx:grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 gap-4  items-center justify-center min-h-screen">
          {meme.map((memes) => {
            return (
              <div
                key={memes.id}
                class="p-1  flex flex-col  rounded-xl group sm:flex  bg-white bg-opacity-50 shadow-xl hover:rounded-2xl"
              >
                <div className="pb-2">
                  <div className="image-container">
                    <img
                      className="img-fluid w-full h-full object-contain"
                      alt="art cover"
                      loading="lazy"
                      src={memes.img}
                    />
                  </div>
                </div>

                {isAdmin === "ADMIN" ? (
                  <button
                    type="button"
                    className="flex w-full py-2 mt-4 bg-cyan-600 text-white button-signup justify-center items-center"
                  >
                    By: {memes?.user.phone}
                  </button>
                ) : null}
                {isAdmin === "ADMIN" ? (
                  <button
                    type="button"
                    onClick={() => deleteData(memes.id)}
                    className="flex w-full py-2 mt-4 bg-red-600 text-white button-signup justify-center items-center"
                  >
                    Delete
                  </button>
                ) : null}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MemeDisplayAll;
