import React, { useState, useEffect } from "react";
import * as htmlToImage from "html-to-image";
import download from "downloadjs";
import Watermark from "react-awesome-watermark";
import { baseURL } from "../resources/apiClient";
import http from "../resources/http";
import swal from "sweetalert";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { change_language, water_mark } from "../store/actions";
import { amharic, orommifa } from "../data/translate";
import { TelegramShareButton } from "react-share";

import Sidebar from "./Sidebar";
const Headers = ({ topText, changeState, clearState }) => {
  const [showHide, setShowHide] = React.useState(false); // state hook to control the visibility of an element
  const toggleShowHide = () => setShowHide(!showHide); // function to toggle the visibility of an element
  const data = useSelector((state) => state.change_language); // hook from react-redux to access the data from the store
  const [showHideUser, setShowHideUser] = React.useState(false); // state hook to control the visibility of an element
  const toggleShowHideUser = () => setShowHideUser(!showHideUser); // function to toggle the visibility of an element
  const logout = () => {
    localStorage.removeItem("meme"); // function to remove the item "meme" from local storage
  };
  const [showHideSidebar, setShowHideSidebar] = React.useState(false); // state hook to control the visibility of an element
  const toggleShowHideSidebar = () => setShowHideSidebar(!showHideSidebar); // function to toggle the visibility of an element
  const dispatch = useDispatch(); // hook from react-redux to dispatch actions to the store
  const user = useSelector((state) => state.user); // hook from react-redux to access the user data from the store

  // function to download PNG image
  const handlePng = () => {
    htmlToImage
      .toPng(document.getElementById("my-meme")) // convert the html element to PNG image
      .then(function (dataUrl) {
        download(dataUrl, "my-meme.png"); // download the PNG image
        // make a post request to the server with the data
        http
          .post(`${baseURL}meme/memes`, {
            title: "Title", // title of the meme
            imgUrl: dataUrl, // image url of the meme
          })
          .then((response) => {
            swal("Meme created successfully").then(() => {
              // show success message
              clearState(); // clear the state
            });
          })
          .catch((error) => {
            swal("Attempt Failed: " + error.message); // show error message
          });
      });
  };

  return (
    <div className="z-50 relative top-0 ">
      <nav class="bg-slate-500  text-white  border-gray-200 px-4 lg:px-6 py-2.5 dark:bg-gray-800">
        <div class="flex p-2 flex-wrap justify-between items-center mx-auto max-w-screen-xl">
          <span class="flex items-center">
            <span class="hidden lg:block self-center text-xl font-semibold whitespace-nowrap dark:text-white">
              Ensaq Memes
            </span>
            <span class="xs:block sm:block md:block lg:hidden xl:hidden 2xl:hidden font-bold self-center text-xl  whitespace-nowrap dark:text-white">
              <svg
                onClick={() => toggleShowHideSidebar()}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6 cursor-pointer"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5"
                />
              </svg>
            </span>
          </span>

          <div className="flex justify-between items-center space-x-10 xs:block sm:block md:block lg:hidden xl:hidden 2xl:hidden">
            <span class="flex flex-col items-center cursor-pointer">
              <span
                onClick={() => toggleShowHide()}
                class="self-center text-xl font-semibold whitespace-nowrap dark:text-white"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              </span>
              {showHide ? (
                <div className="z-50 bg-gray-500 text-white p-1 absolute flex flex-col top-12">
                  <span
                    onClick={() => {
                      dispatch(change_language(amharic));
                      toggleShowHide();
                    }}
                    class="self-center text-xl font-semibold whitespace-nowrap dark:text-white"
                  >
                    አማርኛ
                  </span>
                  <span
                    onClick={() => {
                      dispatch(change_language(orommifa));
                      toggleShowHide();
                    }}
                    class="self-center text-xl font-semibold whitespace-nowrap dark:text-white"
                  >
                    Oromiffa
                  </span>
                </div>
              ) : null}
            </span>
          </div>
          <div className="hidden lg:block">
            <div className="flex justify-between items-center space-x-10 ">
              <span class="sr-only flex items-center cursor-pointer">
                <span class="self-center text-xl font-semibold whitespace-nowrap dark:text-white">
                  <Link className="hover:text-white" to="/templates">
                    {data.templateImages}
                  </Link>
                </span>
              </span>
              <span class="flex items-center cursor-pointer">
                <span class="self-center text-xl font-semibold whitespace-nowrap dark:text-white">
                  <Link className="hover:text-white" to="/templates">
                    {" "}
                    {data.templateImages}
                  </Link>
                </span>
              </span>
              <span class="flex items-center cursor-pointer">
                <span class="self-center text-xl font-semibold whitespace-nowrap dark:text-white">
                  <Link className="hover:text-white" to="/myMeme">
                    {" "}
                    {data.profile}
                  </Link>
                </span>
              </span>
              {/*  */}
              <span class="sr-only flex items-center cursor-pointer">
                <span class="self-center text-xl font-semibold whitespace-nowrap dark:text-white">
                  <Link className="hover:text-white" to="/templates">
                    {" "}
                    {data.templateImages}
                  </Link>
                </span>
              </span>
              <span class=" flex items-center cursor-pointer">
                <span class="self-center text-xl font-semibold whitespace-nowrap dark:text-white">
                  <Link className="hover:text-white" to="/gallery">
                    {" "}
                    {data.gallery}
                  </Link>
                </span>
              </span>
              <span class="flex flex-col items-center cursor-pointer">
                <span
                  onClick={() => toggleShowHide()}
                  class="self-center text-xl font-semibold whitespace-nowrap dark:text-white"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
                    />
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>{" "}
                </span>
                {showHide ? (
                  <div className="z-50 bg-gray-500 text-white p-1 absolute flex flex-col top-12">
                    <span
                      onClick={() => {
                        dispatch(change_language(amharic));
                        toggleShowHide();
                      }}
                      class="self-center text-xl font-semibold whitespace-nowrap dark:text-white"
                    >
                      አማርኛ
                    </span>
                    <span
                      onClick={() => {
                        dispatch(change_language(orommifa));
                        toggleShowHide();
                      }}
                      class="self-center text-xl font-semibold whitespace-nowrap dark:text-white"
                    >
                      Oromiffa
                    </span>
                  </div>
                ) : null}
              </span>

              <span
                onClick={() => toggleShowHideUser()}
                class="flex items-center cursor-pointer"
              >
                <span className="text-center uppercase pt-1.5 bg-sky-800 text-white rounded-full  h-10 w-10">
                  M
                </span>
                {showHideUser ? (
                  <div className="z-50 right-6  bg-gray-500 text-white p-1 absolute flex flex-col top-14">
                    <span class="self-center text-xl font-semibold whitespace-nowrap dark:text-white">
                      <Link
                        onClick={() => {
                          logout();
                          // toggleShowHideUser();
                        }}
                        className="hover:text-white"
                        to="/"
                      >
                        {" "}
                        {data.logout}
                      </Link>
                    </span>
                  </div>
                ) : null}
              </span>
            </div>
          </div>
        </div>
        {showHideSidebar ? (
          <div
            onClick={toggleShowHideSidebar}
            className={`top-0  main left-0  mt-20   text-white fixed h-full z-40  ease-in-out duration-300 ${
              showHideSidebar ? "translate-x-0 " : "translate-x-full"
            }`}
          >
            <Sidebar
              logout={logout}
              toggleShowHideSidebar={toggleShowHideSidebar}
              toggleShowHide={toggleShowHide}
            />
          </div>
        ) : null}
      </nav>
    </div>
  );
};

export default Headers;
