export const amharic = {
  author: "ባለቤት",
  enterPhone: "ስልክዎን አላስገቡም",
  enterPassword: "ፓስወርድ አላስገቡም",
  sendSmsText: "ውድ ደንበኛ የሜም  አገልግሎታችን ለመጠቀም ወደ 9860 OK ብለው በመመዝገብ አካውንት ይፍጠሩ",
  noAccount: "መለያ የለህም?",
  signup: "ክፈት",
  signin: "ግባ",
  phone: "ስልክ ቁጥር",
  password: "የይለፍ ቃል",
  logout: "ውጣ",
  gallery: "የሜሞች ስብስብ",
  noMeme: "Meme እስካሁን የለዎትም:: ለመፍጠር እዚህ ጠቅ ያድርጉ",
  profile: "የርስዎ ሜሞች",
  getStarted: "ጀምር",
  details: "ጽሑፍ ጨምር",
  templateImages: "ቴምፕሌቶች",
  exportImages: "ያውርዱት",
  myMeme: "የኔ ሜም",
  uploadImages: "የራስዎን ፎቶ ይጠቀሙ",
  topText: "የላይኛው ጽሑፍ",
  bottomText: "የታችኛው ጽሑፍ",
  title: "አማርኛ",
  selectMeme: "ሜም ለመስራት ምስል ይምረጡ",
  subscription: "አገልግሎታችንን 3 ቀን በነጻ እና በቀን 2 ብር ያግኙ",
  agreement: "በእኛ ውሎች እና ሁኔታዎች ላይ ይስማሙ",
};

export const orommifa = {
  enterPhone: "lakkoofsa bilbilaa galchaa",
  enterPassword: "jecha icciitii galchi",
  subscription: "Tajaajila keenya Guyyaa 3 Bilisaa fi birrii/guyyaa 2 argadhaa",
  agreement: "Haalawwanii fi dambiiwwan irratti walii galuu",
  selectMeme: "Meme hojjechuuf Fakkii filadhu",
  profile: "ibsama",
  sendSmsText:
    "marsariitii irratti subscribe godhaa akkamitti akka hojjetu ilaalaa",
  noAccount: "Akkaawuntii hin qabduu?",
  signup: "galmaa’uu",
  signin: "galmaa'i",
  phone: "lakkoofsa bilbilaa",
  password: "Jecha iccitii",
  logout: "ba'uu",
  gallery: "Kuusaa memes",
  noMeme: "Ati Meme Hin Qabdu hanga ammaatti. Uumuuf as tuqi",
  author: "Abbaa",
  getStarted: "Eegaluu",
  details: "barruu itti dabali",
  templateImages: "unkaalee",
  exportImages: "buusuu",
  myMeme: "meme koo",
  uploadImages: "Suuraa olkaa'uu",
  topText: "barreeffama gubbaa",
  bottomText: "barreeffama jalaa",
  title: "Orommifa",
};
