import { actionTypes } from "../actions";

const shareUrlReducer = (state = "", action) => {
  //change URL TO SHARE IMAGE LINK
  switch (action.type) {
    case actionTypes.SHARE_URL:
      return action.payload;
    default:
      return state;
  }
};

export { shareUrlReducer };
