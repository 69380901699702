import React from 'react';

function Footer() {
  return (
    <footer>
      <div class="footer-copyright text-center py-3 ">© 2023 Copyright:</div>
    </footer>
  );
}

export default Footer;
