import http from "./../resources/http";
import { swal } from "sweetalert";
import * as htmlToImage from "html-to-image";
import download from "downloadjs";
import { baseURL } from "./../resources/apiClient";
import axios from "axios";
import { shareUrl } from "../store/actions";

export const handlePng = (dispatch) => {
  htmlToImage
    .toPng(document.getElementById("my-meme"))
    .then(function (dataUrl) {
      download(dataUrl, `my-meme.png`); // download the PNG image with file extension

      // convert the dataUrl to a Blob object
      const byteString = atob(dataUrl.split(",")[1]);
      const mimeString = dataUrl.split(",")[0].split(":")[1].split(";")[0];
      const extension = mimeString.split("/")[1];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([ab], { type: mimeString });

      // create a FormData object and append the blob to it

      const formData = new FormData();
      formData.append("img", blob, `my-meme.${extension}`); // append the file with the file extension

      console.log(blob);

      // send a POST request to the server using fetch
      try {
        http
          .post(`${baseURL}meme/memes`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            // swal("Meme created successfully");
            console.log(response.data, "response data");
            dispatch(shareUrl(response.data.img));
          })
          .catch((error) => {
            swal("Meme creation failed");
          });
      } catch (err) {
        swal("Meme Not created successfully");
      }
    });
};
export const handleShare = (dispatch) => {
  htmlToImage
    .toPng(document.getElementById("my-meme"))
    .then(function (dataUrl) {
      // convert the dataUrl to a Blob object
      const byteString = atob(dataUrl.split(",")[1]);
      const mimeString = dataUrl.split(",")[0].split(":")[1].split(";")[0];
      const extension = mimeString.split("/")[1];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([ab], { type: mimeString });

      // create a FormData object and append the blob to it

      const formData = new FormData();
      formData.append("img", blob, `my-meme.${extension}`); // append the file with the file extension

      console.log(blob);

      // send a POST request to the server using axios
      try {
        http
          .post(`${baseURL}meme/memes`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            // swal("Meme created successfully");
            console.log(response.data, "response data");
            dispatch(shareUrl(response.data.img));
          })
          .catch((error) => {
            swal("Meme creation failed");
          });
      } catch (err) {
        swal("Meme Not created successfully");
      }
    });
};
