// This code imports the createStore function from the redux library and the baseReducer from the
// ./reducers file. It also imports a LocalStorage class, creates an instance of it,
// and assigns it to the localStorage variable.
// The createStore function is then called with the baseReducer
// and the value returned by calling localStorage.loadState() as its arguments,
// and the resulting store object is assigned to the store variable.
// A subscribe function is then called on the store object,
// which takes a callback function that calls localStorage.saveState(store.getState()) when invoked.
// Finally, the store variable is exported as the default export.
// This code creates a Redux store and connects it with
// Local Storage so that the state of the store can be persisted in the browser's local storage.

import { createStore } from 'redux';
import baseReducer from './reducers';

import LocalStorage from '../resources/localstorage';

const localStorage = new LocalStorage('meme');

const store = createStore(baseReducer, localStorage.loadState());

store.subscribe(() => {
  localStorage.saveState(store.getState());
});

export default store;
