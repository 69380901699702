import axios from "axios";
import { Modal } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import Headers from "../memeComponents/Headers";
import { baseURL } from "../resources/apiClient";
import { set_token, set_user_data, set_user_status } from "../store/actions";
import LoginHeder from "./LoginHeder";
import FAQs from "../memeComponents/FAQs";
import { amharic } from "./../data/translate";
import { change_language } from "./../store/actions/index";
import { COUNTRY_CODE } from "../data/data";
import { TelegramShareButton } from "react-share";
export default function Login() {
  const [email, setEmail] = React.useState(""); // state hook to store the email value
  const [password, setPassword] = React.useState(""); // state hook to store the password value
  const navigate = useNavigate(); // hook from react-router-dom to navigate to different routes
  const dispatch = useDispatch(); // hook from react-redux to dispatch actions to the store
  const data = useSelector((state) => state.change_language); // hook from react-redux to access the data from the store
  const [show, setShow] = React.useState(false);
  const toggleTerms = () => setShow(!show);
  const [width, setWidth] = React.useState(window.innerWidth); // state hook to store the width of the window
  const [agree, setAgree] = React.useState(false);

  const [viewPassword, setViewPassword] = useState(false);
  const toggleViewPassword = () => setViewPassword(!viewPassword);

  const [faqs, setFaqs] = React.useState(false);
  const toggleFaqs = () => setFaqs(!faqs);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth); // function to update the width of the window
  }

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange); // add event listener when component mounts
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange); // remove event listener when component unmounts
    };
  }, []);

  const isMobile = width <= 768; // check if device is mobile based on the width of the window

  const submit = async (e) => {
    e.preventDefault();
    if (email === "") {
      swal(data.enterPhone);
    } else if (password === "") {
      swal(data.enterPassword);
    } else {
      try {
        axios
          .post(`${baseURL}api/account/login`, {
            username: COUNTRY_CODE + email,
            password: password,
          })
          .then(
            (response) => {
              console.log(response.data, "this is response");
              dispatch(set_token(response.data.token));
              dispatch(set_user_data(response.data.user));
              dispatch(
                set_user_status({
                  loggedIn: true,
                  token: response.data.token,
                })
              );
              if (response.data.user.is_active) {
                response.data.user.user_type === "ADMIN"
                  ? navigate("/admin")
                  : navigate("/templates");
              } else {
                swal("Your account is not active at this time.");
              }
            },
            (err) => {
              // swal('Email and password is not valid');
              swal(data.sendSmsText).then(() => {
                if (isMobile) {
                  handleClick();
                }
              });
            }
          );
      } catch (err) {
        console.log(err);
      }
    }
  };
  const phoneNumber = "9860";
  const message = "ok";

  const handleClick = () => {
    window.open(`sms:${phoneNumber}?body=${message}`);
  };
  useEffect(() => {
    dispatch(change_language(amharic));
  }, []);
  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const shareUrl = "https://example.com";
  const imageUrl = "";
  const title = "Check out this cool image!";
  return (
    <>
      <div className="relative bg-slate-200 h-screen">
        <div className="absolute top-0 left-0 right-0 bottom-0 bg-center bg-cover"></div>
        <LoginHeder />

        <div className="flex h-screen">
          <div className=" w-1/2  text-white  text-4xl  flex  justify-center items-center bg-sky-600">
            <div className="flex flex-col justify-center items-center  space-y-10">
              <span className="font-bold animate-bounce">
                Create, Share, Laugh
              </span>
              <span className="text-2xl">
                Empowering creativity, one meme at a time.
              </span>

              <span className="absolute bottom-4 mt-36 flex justify-center items-center text-xs">
                ©2023 by Enable ET Consulting. All Rights Reserved.
              </span>
            </div>
          </div>
          <div className="w-full sm:w-1/2 bg-slate-200 flex justify-center items-center">
            <div className="relative px-6 py-8 w-2/3 flex flex-col space-y-2 justify-center items-center">
              <span className="block text-blue-900 text-3xl font-bold mb-2">
                Login
              </span>
              <span className="block text-sky-900  font-bold mb-2">
                {data.subscription}
              </span>
              <form>
                <div className="mb-4">
                  <label
                    className="block text-blue-900 text-xl font-bold mb-2"
                    htmlFor="phoneNumber"
                  >
                    {data.phone}
                  </label>
                  <div className="border border-red-900 rounded flex items-center border bg-white">
                    <span className="font-bold h-full py-3 p-1 border-r-2 border-gray-200">
                      +251
                    </span>
                    <input
                      class=" appearance-none block w-full h-full py-3 px-4  leading-tight focus:outline-none "
                      id="phoneNumber"
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder={" 9********"}
                      required
                    />
                  </div>
                </div>

                <div className="mb-6">
                  <label
                    className="block text-blue-900 text-xl font-bold mb-2"
                    htmlFor="password"
                  >
                    {data.password}
                  </label>
                  <div className="border border-red-900 rounded flex items-center border bg-white">
                    <span className="font-bold h-full py-3 p-1 border-r-2 border-gray-200">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
                        />
                      </svg>
                    </span>
                    <input
                      class=" appearance-none block w-full h-full py-3 px-4  leading-tight focus:outline-none "
                      id="phoneNumber"
                      type={viewPassword ? "text" : "password"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder={data.password}
                      required
                    />
                    <span
                      className="cursor-pointer mr-2"
                      onClick={() => toggleViewPassword()}
                    >
                      {viewPassword ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                          />
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                          />
                        </svg>
                      )}
                    </span>
                  </div>
                </div>
                <div class="flex items-start mb-6 cursor-pointer">
                  <input
                    type="checkbox"
                    className="w-6 h-6 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800"
                    checked={checked}
                    onChange={handleChange}
                  />
                  <label
                    onClick={() => toggleTerms()}
                    for="remember"
                    class="ml-2 underline text-xl cursor-pointer font-medium text-gray-900 dark:text-gray-400"
                  >
                    {data.agreement}
                  </label>
                </div>

                <div className="flex flex-col space-y-4 items-center justify-between">
                  <button
                    disabled={!checked}
                    onClick={(e) => submit(e)}
                    className="bg-blue-500 w-full hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="submit"
                  >
                    {data.signin}
                  </button>
                </div>
                <div className="mt-2 flex justify-between items-center">
                  <div className="flex items-center space-x-2">
                    <span>Don't have account?</span>
                    <button
                      type="button"
                      onClick={() =>
                        swal(data.sendSmsText).then(() => {
                          if (isMobile) {
                            handleClick();
                          }
                        })
                      }
                      className="font-extrabold cursor-pointer  text-blue-900"
                    >
                      Register
                    </button>
                  </div>
                  <div
                    class="flex  justify-center items-center  cursor-pointer"
                    onClick={() => {
                      toggleFaqs();
                    }}
                  >
                    <label
                      for="remember"
                      class="font-extrabold cursor-pointer  text-blue-900 dark:text-gray-400"
                    >
                      <span> HOW TO</span>
                    </label>
                  </div>{" "}
                </div>
              </form>
            </div>
          </div>
        </div>
        <Modal show={show} className="mt-1" size="lg">
          <Modal.Header closeButton onClick={() => toggleTerms()}>
            <Modal.Title className="text-4xl font-bold">
              Terms and Conditions
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="flex flex-col space-y-10">
              <div>
                <p className="font-bold text-2xl">How to register</p>
                <span className=" text-xl">
                  Dear customer, you will be registered as a user of the service
                  by sending OK to 9860
                </span>
              </div>
              <div>
                <p className="font-bold text-2xl">Subscription</p>
                <span className=" text-xl">
                  Once you subscribed to our website or service, we charge 2
                  birr per day until you send an unsubscription request. you can
                  send unsubscribe request by clicking "unsubscribe" link on the
                  home page.
                </span>
              </div>
              <div>
                <p className="font-bold text-2xl">Services</p>
                <span className=" text-xl">
                  <ol className="flex flex-col space-y-2">
                    <li>
                      <span className="font-bold">
                        Image captioning service:
                      </span>
                      Image captioning service: allow users to add text to
                      pre-selected or uploaded images to create custom memes.
                    </li>
                    <li>
                      <span className="font-bold">Template library:</span>
                      provide a wide variety of popular meme templates that
                      users can customize with their own images and text.
                    </li>
                    <li>
                      <span className="font-bold">Trending memes:</span>
                      show the most popular memes based on social media trends
                      and user engagement, allowing users to quickly create
                      their own version of popular memes.
                    </li>
                  </ol>
                </span>
              </div>
              {/* {agree ? (
                <div className="flex w-2/6 flex-col space-y-4 items-center justify-end">
                  <button
                    onClick={() => {
                      setAgree(false);
                      toggleTerms();
                    }}
                    className="bg-blue-500 w-full hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="submit"
                  >
                    Decline
                  </button>
                </div>
              ) : (
                <div className="flex w-2/6 flex-col space-y-4 items-center justify-end">
                  <button
                    onClick={() => {
                      setAgree(true);
                      toggleTerms();
                    }}
                    className="bg-blue-500 w-full hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="submit"
                  >
                    Accept
                  </button>
                </div>
              )} */}
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={faqs} className="mt-1" size="lg">
          <Modal.Header closeButton onClick={() => toggleFaqs()}>
            <Modal.Title>Frequently Asked Questions.</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FAQs />
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}
