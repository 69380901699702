// This code uses the useSelector hook from the react-redux
// library to access the "change_language" state from the
// store and also it uses the useParams hook from the react-router-dom library
//  to access the dynamic parameters from the URL.

import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { templates } from "../data/data";
import Headers from "./Headers";
import { useSelector } from "react-redux";
import { baseURL } from "./../resources/apiClient";
import axios from "axios";
import LoadingSpinner from "./SpinnerLoader";
import { useQuery, queryCache } from "react-query";
import swal from "sweetalert";
import http from "./../resources/http";
import { brushBackground } from "../utils/constants";

const fetchData = async () => {
  const { data } = await axios.get(`${baseURL}meme/meme-url`);
  return data;
};

const Templates = () => {
  // const datas = useSelector((state) => state.change_language); // useSelector hook is used to access the state from the store
  let localStorageData = JSON.parse(localStorage.getItem("meme"));
  const isAdmin = localStorageData?.user?.user_type;
  const { id } = useParams(); // useParams hook is used to access the dynamic parameters from the URL in react-router-dom
  const { data, isLoading, error, refetch } = useQuery(
    "memeTemplates",
    fetchData
  );

  const navigate = useNavigate();

  const [memes, setMemes] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    refetch();
  }, []);
  const deleteData = (id) => {
    // http
    //   .delete(`${baseURL}meme/templates/${id}`)
    //   .then((response) => {
    //     swal("Success!", "Template deleted Successfully!", "success");
    //     refetch();
    //   })
    //   .catch((err) => {
    //     alert(err);
    //   });
    http
      .delete(`${baseURL}meme/meme-url/${id}`)
      .then((response) => {
        swal("Success!", "Template deleted Successfully!", "success");
        refetch();
      })
      .catch((err) => {
        alert(err);
      });
  };
  console.log(data, "memes");
  return (
    <div className="h-screen overflow-y-auto">
      <Headers />
      <div className="w-full flex flex-col justify-center items-center">
        <p className="text-4xl font-extrabold">Popular memes right now</p>
        <div className="w-full mb-2">
          <img className="h-10 w-full" src={brushBackground} />
        </div>
      </div>
      {!isLoading ? (
        <div class="grid sx:grid-cols-1 m-2 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 gap-4  items-center justify-center">
          {data?.map((memes) => {
            return (
              <div
                key={memes.id}
                class="p-1 cursor-pointer border-2 border-cyan-200  flex flex-col  rounded-xl group sm:flex space-x-6 bg-white bg-opacity-50 shadow-xl hover:rounded-2xl"
              >
                <div className="pb-2">
                  <div className="image-container">
                    <img
                      onClick={() => {
                        navigate(`/meme-maker/${memes.id}`);
                        refetch();
                      }}
                      className="img-fluid object-contain w-full h-full"
                      alt="art cover"
                      loading="lazy"
                      src={memes.img}
                    />
                  </div>
                </div>
                <div className="flex mb-2">
                  <button
                    type="button"
                    onClick={() => navigate(`/meme-maker/${memes.id}`)}
                    className="flex mx-16 w-36 py-2 mt-10 bg-cyan-600 text-white button-signup justify-center items-center"
                  >
                    Edit
                  </button>

                  {isAdmin === "ADMIN" ? (
                    <button
                      type="button"
                      onClick={() => deleteData(memes.id)}
                      className="flex mx-16 w-36 py-2 mt-10 bg-red-600 text-white button-signup justify-center items-center"
                    >
                      Delete
                    </button>
                  ) : null}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </div>
  );
};

export default Templates;
