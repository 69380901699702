import { amharic, orommifa } from '../../data/translate';
import { actionTypes } from '../actions';

const languageReducer = (state = amharic, action) => {
  //change language reducer
  switch (action.type) {
    case actionTypes.SET_LANGUAGE:
      return action.payload;
    default:
      return state;
  }
};

export { languageReducer };
