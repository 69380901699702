export const COUNTRY_CODE = "251";

export const templates = [
  {
    id: 1,
    imgUrl:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS4w6927fEU2eJT6jUoLPwgvSpLLqCVQXUHFR4-aK9mtQ&s",
  },
  {
    id: 2,
    imgUrl:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSHmQwvqI8V301l_JA9jJkumbnantoZlIDFuxWHs1KDoIudDtkDGo1MwZlUsOn4QSqxd1U&usqp=CAU",
  },
  {
    id: 3,
    imgUrl:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTphB5IB3rxbiALBn-hTQPrjQVYmgu-XaLML3NiIRBWA9PiuBknTPdReJjsVkzOqTDVT1c&usqp=CAU",
  },
  {
    id: 4,
    imgUrl:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRmr8X9FteKI-kMq_7cwSjXtUeBGZ4bpH0PkhnUp1NGdA&s",
  },
  {
    id: 5,
    imgUrl:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTfYn9OCwTRToG_DwDff4lgfgL0A4pc5gV1Pw&usqp=CAU",
  },
  {
    id: 6,
    imgUrl:
      "https://newfastuff.com/wp-content/uploads/2020/04/4cvvyap5y7t41-300x166.png",
  },
];
