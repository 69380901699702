import axios from "axios";
import React, { useState, useEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import swal from "sweetalert";
import { baseURL } from "./../resources/apiClient";
import http from "./../resources/http";

const ManageUsers = () => {
  const [id, setId] = useState();
  const [val, setVal] = useState();
  const [username, setUsername] = useState();
  const [email, setEmail] = useState();
  const [company, setCompany] = useState();
  const [userType, setUsertype] = useState();
  const [status, setStatus] = useState();
  const [modal, setModal] = useState(false);
  const showModal = () => setModal(!modal);
  const [edit, setEditModal] = useState(false);
  const showEditModal = () => setEditModal(!edit);
  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  // const [userList, setUserList] = useState(data.slice(0, data.length));
  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;

  const pageCount = Math.ceil(data.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const handleDeleteConfirmation = () =>
    setDeleteConfirmation(!deleteConfirmation);

  const fetchData = () => {
    axios
      .get(`${baseURL}api/account/list-accounts`)
      .then((response) => {
        setData(response.data);
      })
      .then(
        (response) => {},
        (err) => {
          console.log("No Data To Show");
        }
      )
      .catch((err) => {
        return false;
      });
  };
  useEffect(() => {
    fetchData();
  }, []);

  console.log(data, "users");
  const DisplayData = data
    ?.slice(pagesVisited, pagesVisited + usersPerPage)
    ?.filter((row) => row?.phone?.match(new RegExp(searchValue, "i")) !== null)
    ?.map((users) => {
      return (
        <>
          <tr key={users.id} className="text-xl">
            <td data-label="ID">{users.id} </td>
            <td data-label="Company">{users.phone}</td>
            <td data-label="Company">{users.user_type}</td>
            <td data-label="Company">
              {users.is_active ? "Active" : "InActive"}
            </td>
            <td className="flex space-x-2">
              <svg
                onClick={() => {
                  setVal(users.id);
                  setUsername(users.email);
                  setEmail(users.email);
                  setUsertype(users.user_type);
                  setStatus(users.phone);
                  showEditModal();
                }}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6 cursor-pointer text-green-500"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                />
              </svg>

              <svg
                onClick={() => {
                  setId(users.id);
                  handleDeleteConfirmation();
                }}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6 cursor-pointer text-red-900"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                />
              </svg>
            </td>
          </tr>
        </>
      );
    });
  console.log(DisplayData, "user info here");
  return (
    <div className="z-100 p-16">
      <div className="flex flex-col text-black pb-10 pt-3">
        <div className="rounded-md shadow overflow-auto mx-8 sm:mx-28">
          <div className="bg-slate-600 text-white p-3 flex justify-between items-center">
            <div>
              <span className="font-bold ">Users</span>
            </div>

            <div className="flex justify-between items-center space-x-4">
              <div className="flex justify-between items-center">
                <div className="flex justify-center space-x-4 items-center">
                  <div class="">
                    <input
                      type="text"
                      id="email"
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Search user by Phone"
                      required
                    />
                  </div>
                  <div className="border cmbutton  p-1.5 rounded cursor-pointer">
                    <svg
                      onClick={() => showModal()}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <table class="tables rounded-md overflow-hidden">
            <thead className="sticky-header">
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Phone</th>
                <th scope="col">User Type</th>
                <th scope="col">Status</th>
                <th scope="col">Manage</th>
              </tr>
            </thead>
            <tbody>{DisplayData}</tbody>
          </table>
        </div>
        <br />
        <div className="flex flex-col justify-center items-center">
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            activeClassName={"paginationActive"}
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={"paginationBttns"}
            previousLinkClassName={"previousBttn"}
            nextLinkClassName={"nextBttn"}
            disabledClassName={"paginationDisabled"}
          />
        </div>
      </div>
    </div>
  );
};

export default ManageUsers;
