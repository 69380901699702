import axios from 'axios';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import { baseURL } from '../resources/apiClient';

export default function Signup() {
  const [user, setUser] = useState({
    username: '', // state hook to store the username
    phone: '', // state hook to store the phone number
    email: '', // state hook to store the email
    password: '', // state hook to store the password
  });
  const navigate = useNavigate(); // hook from react-router-dom to navigate to different routes

  const submit = async (e) => {
    //a function to signup user information
    e.preventDefault();
    if (user.email === '') {
      swal('Please Enter Email');
    } else if (user.password === '') {
      swal('Please enter password');
    } else if (user.email.length < 3) {
      swal('Email length should be at least six character');
    } else {
      try {
        axios
          .post(`${baseURL}api/account/signup`, {
            // making a post request to the endpoint
            username: user.username, // sending the username from state
            phone: user.phone, // sending the phone from state
            email: user.email, // sending the email from state
            password: user.password, // sending the password from state
          })
          .then(
            (response) => {
              // if request is successful
              swal('Success', 'Account successfully signed up', 'success'); // show success message
              navigate('/'); // navigate to the homepage
            },
            (err) => {
              // if request failed
              swal(
                'Signup failed ' +
                  err.response.data.phone + // display the error message from the server, specifically the phone field
                  ' ' +
                  err.response.data.email // display the error message from the server, specifically the email field
              );
            }
          );
      } catch (err) {
        console.log(err);
      }
    }
  };
  return (
    <div className="bg-gradient-to-r from-indigo-500 to-rose-600/40 relative flex flex-col justify-center min-h-screen overflow-hidden">
      <div className="w-full p-6 m-auto bg-white rounded-md shadow-xl shadow-rose-600/40 ring ring-2 ring-purple-600 lg:max-w-xl">
        <h1 className="text-3xl font-semibold text-center text-purple-700 underline uppercase decoration-wavy">
          Sign up
        </h1>
        <form className="mt-6">
          <div className="mb-2">
            <label
              for="email"
              className="block text-sm font-semibold text-gray-800"
            >
              Username
            </label>
            <input
              type="text"
              value={user.username}
              onChange={(e) => setUser({ ...user, username: e.target.value })}
              className="block w-full px-4 py-2 mt-2 text-purple-700 bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
            />
          </div>
          <div className="mb-2">
            <label
              for="email"
              className="block text-sm font-semibold text-gray-800"
            >
              Email
            </label>
            <input
              type="email"
              value={user.email}
              onChange={(e) => setUser({ ...user, email: e.target.value })}
              className="block w-full px-4 py-2 mt-2 text-purple-700 bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
            />
          </div>
          <div className="mb-2">
            <label
              for="email"
              className="block text-sm font-semibold text-gray-800"
            >
              Phone number
            </label>
            <input
              type="number"
              value={user.phone}
              onChange={(e) => setUser({ ...user, phone: e.target.value })}
              className="block w-full px-4 py-2 mt-2 text-purple-700 bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
            />
          </div>
          <div className="mb-2">
            <label
              for="password"
              className="block text-sm font-semibold text-gray-800"
            >
              Password
            </label>
            <input
              type="password"
              value={user.password}
              onChange={(e) => setUser({ ...user, password: e.target.value })}
              className="block w-full px-4 py-2 mt-2 text-purple-700 bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
            />
          </div>
          <div className="mt-6">
            <button
              onClick={(e) => submit(e)}
              className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-purple-700 rounded-md hover:bg-purple-600 focus:outline-none focus:bg-purple-600"
            >
              Signup
            </button>
          </div>
        </form>

        <p className="mt-8 text-base  font-light text-center text-gray-700">
          {' '}
          Already have an account?{' '}
          <p
            onClick={() => navigate('/')}
            className="text-blue-700 cursor-pointer"
          >
            Login
          </p>
        </p>
      </div>
    </div>
  );
}
