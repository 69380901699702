import React, { useState } from "react";
import Header from "./SlideShow";
import Footer from "./Footer";
import Draggable from "react-draggable";
import ContentEditable from "react-contenteditable";
import "../index.css";
import {
  FacebookIcon,
  FacebookShareButton,
  InstapaperIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import RightSide from "./RightSide";
import Headers from "./Headers";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import DynamicDraggable from "./DynamicDraggable";
import axios from "axios";
import { baseURL } from "./../resources/apiClient";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { templates } from "./../data/data";
import { handlePng } from "./../utils/handleDownload";
import LoadingSpinner from "./SpinnerLoader";
import { useQuery } from "react-query";
import MemeLoaderAnimation from "./MemeLoaderAnimation";
import http from "../resources/http";

function MemeGeneratorFromTemplates(props) {
  const [memes, setMemes] = useState([]);
  const datas = useSelector((state) => state.change_language);

  const fetchData = async () => {
    const { data } = await axios.get(`${baseURL}meme/meme-url`);
    return data;
  };
  const { data, isLoading, error, refetch } = useQuery(
    "memeTemplates",
    fetchData
  );
  // console.log(data, "data cached");

  const { id } = useParams();
  const image = data?.filter((img) => img.id === Number(id))[0];

  console.log(image?.img, "image image");

  const [users, setUsers] = useState(
    Array(2).fill({
      firstName: "",
      rotation: 0,
      fontSize: 54,
      fontWeight: "bolder",
      color: "#000000",
    })
  );

  function resetClick() {
    swal({
      title: "Are you sure?",
      text: "This will reset all the text input changes including font size, color, and font weight.",
      icon: "warning",
      buttons: ["No, keep it", "Yes, reset it!"],
    }).then((result) => {
      if (result === true) {
        setUsers([
          {
            firstName: "",
            fontSize: 54,
            fontWeight: "bolder",
            color: "#000000",
          },
        ]);
      }
    });
  }
  function handleRotate(i, direction) {
    let updatedUsers = [...users];
    let rotation = updatedUsers[i].rotation;
    if (direction === "left") {
      rotation -= 45;
    } else {
      rotation += 45;
    }
    updatedUsers[i] = { ...updatedUsers[i], rotation };
    setUsers(updatedUsers);
  }
  function addClick() {
    setUsers((prevState) => [
      ...prevState,
      { firstName: "", fontSize: 54, fontWeight: "bolder", color: "#000000" },
    ]);
  }

  function createUI() {
    const fontSizes = [
      12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40, 42, 44, 46,
      48, 50, 52, 54, 56, 58, 60, 62, 64, 66, 68, 70, 72,
    ];
    const fontWeights = ["normal", "bold", "bolder", "lighter"];

    return users.map((el, i) => (
      <div key={i} className="flex space-x-4 border mb-2 rounded ">
        <input
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          name="firstName"
          value={el.firstName || ""}
          onChange={(e) => handleChanges(e, i)}
          placeholder={i % 2 === 0 ? datas.topText : datas.bottomText}
        />
        <div className="flex justify-center items-center space-x-2">
          <div className="flex justify-center space-x-3 items-center border rounded ">
            {/* <label>Font Size</label> */}
            <select
              className="border border-primary rounded"
              name="fontSize"
              value={el.fontSize || 28}
              onChange={(e) => handleChanges(e, i)}
            >
              {fontSizes.map((size) => (
                <option value={size}>{size}</option>
              ))}
            </select>
          </div>
          <div className="flex justify-center  items-center border rounded ">
            {/* <label>Font Weight</label> */}
            <select
              className="border border-primary rounded"
              name="fontWeight"
              value={el.fontWeight || "bolder"}
              onChange={(e) => handleChanges(e, i)}
            >
              {fontWeights.map((weight) => (
                <option value={weight}>{weight}</option>
              ))}
            </select>
          </div>
          <div className="flex justify-center space-x-3 items-center border rounded ">
            {/* <label>Font Color</label> */}
            <input
              type="color"
              name="color"
              value={el.color || "#000000"}
              onChange={(e) => handleChanges(e, i)}
              className="hide-color-picker-icon border border-primary  py-1 rounded-lg shadow-md focus:outline-none focus:shadow-outline-blue-500"
            />
          </div>

          <button
            type="button"
            onClick={() => removeClick(i)}
            className="px-2 py-1 rounded-lg shadow-md focus:outline-none focus:shadow-outline-blue-500 bg-red-500 hover:bg-red-700 text-white"
          >
            X
          </button>
        </div>
      </div>
    ));
  }

  function handleChanges(e, i) {
    const { name, value } = e.target;
    let updatedUsers = [...users];
    updatedUsers[i] = { ...updatedUsers[i], [name]: value };
    setUsers(updatedUsers);
  }

  function removeClick(i) {
    let updatedUsers = [...users];
    updatedUsers.splice(i, 1);
    setUsers(updatedUsers);
  }

  function handleSubmit(event) {
    alert("A name was submitted: " + JSON.stringify(users));
    event.preventDefault();
  }

  function resetClick() {
    setUsers([
      { firstName: "", fontSize: 54, fontWeight: "normal", color: "#000000" },
    ]);
  }

  ///
  const [rotation, setRotation] = useState(0);
  const [topText, setTopText] = useState("");
  const [bottomText, setBottomText] = useState("");

  function handleDrag(e, data) {
    setRotation(data.rotation);
  }
  const handleRotateClick = () => {
    setRotation(rotation + 90);
  };
  const url =
    "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/640px-Image_created_with_a_mobile_phone.png";

  const [fontSize, setFontSize] = React.useState(16);
  const [fontSizeBottom, setFontSizeBottom] = React.useState(16);
  const [fontWeightBottom, setFontWeightBottom] = React.useState("normal");
  const [fontWeight, setFontWeight] = React.useState("normal");

  const handleChange = (event) => {
    setFontSize(event.target.value);
  };
  const handleChangeBottom = (event) => {
    setFontSizeBottom(event.target.value);
  };
  const handleFontWeightChangeBottom = (event) => {
    setFontWeightBottom(event.target.value);
  };
  const handleFontWeightChange = (event) => {
    setFontWeight(event.target.value);
  };

  //Handle canvas
  const [canvasPosition, setCanvasPosition] = useState("none");
  const [waterMark, setWaterMark] = useState("");

  const handleChangeCanvas = (event) => {
    setCanvasPosition(event.target.value);
  };

  let canvasClasses = "";
  if (canvasPosition === "top") {
    canvasClasses = "pt-16";
  } else if (canvasPosition === "bottom") {
    canvasClasses = "pb-16";
  } else if (canvasPosition === "both") {
    canvasClasses = "pt-16 pb-16";
  }

  const [rotated, setRotated] = useState(false);

  // function to rotate the div element
  const rotate = () => {
    setRotated((rotated) => !rotated);
  };

  // const watermarkText = useSelector((state) => state.water_mark);
  const [watermarkText, setWatermarkText] = useState("");

  function changeState() {
    setWatermarkText("By Enable ET");
  }
  const [meme, setMeme] = useState([]);
  const [loading, setLoading] = useState(false);

  // console.log(memes, "images", id, "is id");
  function clearState() {
    setWatermarkText("");
  }
  const [templateImage, setTemplateImage] = useState([]);
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  useEffect(() => {
    setLoadingSpinner(true);
    axios
      .get(`${baseURL}meme/templates/${id}`)
      .then((response) => {
        console.log(response.data, "another");
        setTemplateImage(response.data.imgUrl);
        setLoadingSpinner(false);
      })
      .catch((error) => {
        swal("Template Image not found");
      });
  }, []);
  // console.log(templateImage, "templateImage");
  const handleInputChange = (event) => {
    // Create a FileReader to read the selected image file
    const fileReader = new FileReader();
    fileReader.readAsDataURL(event.target.files[0]);
    // Set the URL of the selected image as the new image URL
    fileReader.onload = () => setTemplateImage(fileReader.result);
  };

  const dispatch = useDispatch();
  const shareImgUrl = useSelector((state) => state.shareUrl);
  console.log(shareImgUrl, "shareImgUrl");

  const [showSocials, setShowSocials] = useState(false);

  const toggleShowSocials = () => setShowSocials(!showSocials);

  return (
    <div className="scrollable-y">
      <Headers
        topText={users}
        changeState={changeState}
        clearState={clearState}
      />
      {/* <DynamicDraggable /> */}

      <div className="p-2 bg-slate-200 overflow-y-auto overflow-x-auto">
        <div className="grid sx:grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-4">
          <div className="mt-4">
            <div className="container">
              <div className="row ">
                <div className="col-md-12">
                  <div className="  overflow-hidden mb-4">
                    <div className="col-lg-16 p-2 ">
                      {templateImage.length > 1 ? (
                        <div
                          id="my-meme"
                          style={{
                            transform: `rotate(${rotated ? 180 : 0}deg)`,
                          }}
                          className="z-30 vnicornMeme relative "
                        >
                          <div
                            className={`bg-white  ${canvasClasses} flex flex-col justify-center items-center text-center `}
                          >
                            {users.map((user, index) => (
                              <Draggable key={index}>
                                <span
                                  className={`absolute cursor-pointer ${
                                    index === 0 ? "top-6" : "bottom-2"
                                  }`}
                                  style={{
                                    fontSize: `${user.fontSize}px`,
                                    fontWeight: user.fontWeight,
                                    color: user.color,
                                    transform: `rotate(${user.rotation}deg)`,
                                  }}
                                >
                                  {user.firstName}
                                </span>
                              </Draggable>
                            ))}

                            <img
                              src={templateImage}
                              className=" object-cover"
                              alt=""
                              width="100%"
                              style={{ objectFit: "contain" }}
                            />
                          </div>
                        </div>
                      ) : (
                        <MemeLoaderAnimation />
                      )}
                    </div>
                    <div className="flex justify-between items-center space-x-10 ">
                      <span class="flex items-center cursor-pointer p-4">
                        {Array.isArray(users) &&
                        users.length > 0 &&
                        users[0].firstName ? (
                          <div className="flex justify-center items-center space-x-4">
                            <span class="flex  rounded p-1 bg-gray-800 px-2 space-x-2 self-center text-xl font-semibold whitespace-nowrap text-white">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="w-6 h-6"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                                />
                              </svg>

                              <button
                                onClick={() => {
                                  handlePng(dispatch);
                                }}
                              >
                                {datas.exportImages}
                              </button>
                            </span>
                            <div>
                              <span
                                onClick={() => {
                                  toggleShowSocials(dispatch);
                                  toggleShowSocials();
                                }}
                              >
                                Share
                              </span>
                            </div>
                            {showSocials ? (
                              <div className="flex justify-center items-center space-x-4">
                                <TelegramShareButton
                                  url={shareImgUrl}
                                  image={shareImgUrl}
                                >
                                  <TelegramIcon siz={1} borderRadius={10} />
                                </TelegramShareButton>
                                <FacebookShareButton
                                  url={shareImgUrl}
                                  image={shareImgUrl}
                                >
                                  {" "}
                                  <FacebookIcon siz={1} borderRadius={10} />
                                </FacebookShareButton>
                                <TwitterShareButton
                                  url={shareImgUrl}
                                  image={shareImgUrl}
                                >
                                  {" "}
                                  <TwitterIcon siz={1} borderRadius={10} />
                                </TwitterShareButton>
                                <WhatsappShareButton
                                  url={shareImgUrl}
                                  image={shareImgUrl}
                                >
                                  {" "}
                                  <WhatsappIcon siz={1} borderRadius={10} />
                                </WhatsappShareButton>
                              </div>
                            ) : null}
                          </div>
                        ) : null}
                      </span>
                    </div>
                  </div>
                  {/* Works!! */}
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1 z-50 mt-4  p-2 d-flex flex-column position-static space-y-10">
            {/* <div className="hidden  lg:block shadow-lg p-3 mb-3 bg-white rounded">
            </div> */}
            <div className="shadow-lg p-3 mb-3 bg-white rounded">
              <p className="text-muted">{datas.details}</p>
              <form onSubmit={handleSubmit} className="flex flex-col space-y-2">
                {createUI()}
                <input
                  className="sr-only form-control border-info"
                  type="text"
                  name="authorText"
                  // placeholder={data.author}
                  // value={props.data.authorText}
                  // onChange={props.handleChange}
                />
                <div className="flex justify-between items-center">
                  <input
                    className="bg-green-600 text-white px-2 py-1 rounded"
                    type="button"
                    value="+ Add more"
                    onClick={addClick}
                  />
                  <label
                    htmlFor="image-upload"
                    className="bg-blue-500 py-2 font-bold rounded px-2 text-white cursor-pointer flex items-center justify-center"
                  >
                    <svg
                      className="w-6 h-6 mr-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                    </svg>
                    <span>{datas.uploadImages}</span>
                    <input
                      type="file"
                      id="image-upload"
                      className="hidden"
                      onChange={handleInputChange}
                    />
                  </label>
                  <input
                    className="bg-red-600 text-white px-2 py-1 rounded"
                    type="button"
                    value="Reset All"
                    onClick={resetClick}
                  />
                </div>
              </form>
            </div>
            <div className="rounded p-2 flex justify-around  bg-orange-600  mb-2 m-10 mt-10 ">
              <div className="flex justify-center items-center space-x-4">
                <span className="text-white font-bold">Spacing</span>
                <select value={canvasPosition} onChange={handleChangeCanvas}>
                  <option value="none">None</option>
                  <option value="top">Top</option>
                  <option value="bottom">Bottom</option>
                  <option value="both">Both</option>
                </select>
              </div>

              <div className="flex justify-center items-center space-x-4">
                <span className="text-white font-bold">Rotate Image</span>
                <button
                  className="bg-blue-500 text-white p-2 rounded"
                  onClick={rotate}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MemeGeneratorFromTemplates;
