import React, { useState } from "react";
import * as htmlToImage from "html-to-image";
import download from "downloadjs";
import Watermark from "react-awesome-watermark";
import { baseURL } from "../resources/apiClient";
import http from "../resources/http";
import swal from "sweetalert";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { amharic, orommifa } from "../data/translate";
import { change_language } from "../store/actions";

const AdminHeader = ({ topText }) => {
  const [showHideUser, setShowHideUser] = React.useState(false); // state hook to control the visibility of an element
  const toggleShowHideUser = () => setShowHideUser(!showHideUser); // function to toggle the visibility of an element
  const logout = () => {
    localStorage.removeItem("meme"); // function to remove the item "meme" from local storage
  };

  const user = useSelector((state) => state.user); // hook from react-redux to access the user data from the store

  const [showHide, setShowHide] = React.useState(false); // state hook to control the visibility of an element
  const toggleShowHide = () => setShowHide(!showHide); // function to toggle the visibility of an element
  const data = useSelector((state) => state.change_language); // hook from react-redux to access the data from the store

  const [showHideSidebar, setShowHideSidebar] = React.useState(false); // state hook to control the visibility of an element
  const toggleShowHideSidebar = () => setShowHideSidebar(!showHideSidebar); // function to toggle the visibility of an element
  const dispatch = useDispatch(); // hook from react-redux to dispatch actions to the store

  return (
    <div className="z-50 relative top-0 ">
      <nav class="bg-amber-700  text-white  border-gray-200 px-4 lg:px-6 py-2.5 dark:bg-gray-800">
        <div class="flex p-2 flex-wrap justify-between items-center mx-auto max-w-screen-xl">
          <span class="flex items-center">
            <span class="hidden lg:block self-center text-xl font-semibold whitespace-nowrap dark:text-white">
              Ensaq Memes
            </span>

            <span class="xs:block sm:block md:block lg:hidden xl:hidden 2xl:hidden font-bold self-center text-xl  whitespace-nowrap dark:text-white">
              <svg
                onClick={() => toggleShowHideSidebar()}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6 cursor-pointer"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5"
                />
              </svg>
            </span>
          </span>

          <div className="flex justify-between items-center space-x-10 ">
            <span class=" flex items-center cursor-pointer">
              <span class="self-center text-xl font-semibold whitespace-nowrap dark:text-white">
                <Link className="hover:text-white" to="/admin">
                  {" "}
                  {/* {data.templateImages} */}
                  Upload Templates
                </Link>
              </span>
            </span>
            {/* <span class=" flex items-center cursor-pointer">
              <span class="self-center text-xl font-semibold whitespace-nowrap dark:text-white">
                <Link className="hover:text-white" to="/manage-users">
                  {" "}
                  Manage Users
                </Link>
              </span>
            </span> */}
            <span class=" flex items-center cursor-pointer">
              <span class="self-center text-xl font-semibold whitespace-nowrap dark:text-white">
                <Link className="hover:text-white" to="/upload-gallery">
                  {" "}
                  Upload Gallery
                </Link>
              </span>
            </span>
            <span class=" flex items-center cursor-pointer">
              <span class="self-center text-xl font-semibold whitespace-nowrap dark:text-white">
                <Link className="hover:text-white" to="/admin-gallery">
                  {" "}
                  Manage Gallery
                </Link>
              </span>
            </span>
            {/* <span class="flex flex-col items-center cursor-pointer">
              <span
                onClick={() => toggleShowHide()}
                class="self-center text-xl font-semibold whitespace-nowrap dark:text-white"
              >
                {data.title === "አማርኛ" ? "አማርኛ" : "Orommifa"}
              </span>
              {showHide ? (
                <div className="z-50 bg-gray-500 text-white p-1 absolute flex flex-col top-12">
                  <span
                    onClick={() => {
                      dispatch(change_language(amharic));
                      toggleShowHide();
                    }}
                    class="self-center text-xl font-semibold whitespace-nowrap dark:text-white"
                  >
                    አማርኛ
                  </span>
                  <span
                    onClick={() => {
                      dispatch(change_language(orommifa));
                      toggleShowHide();
                    }}
                    class="self-center text-xl font-semibold whitespace-nowrap dark:text-white"
                  >
                    Oromiffa
                  </span>
                </div>
              ) : null}
            </span> */}
            <span
              onClick={() => toggleShowHideUser()}
              class="flex items-center cursor-pointer"
            >
              <span className="text-center uppercase pt-1.5 bg-sky-800 text-white rounded-full  h-10 w-10">
                A
              </span>
              {showHideUser ? (
                <div className="z-50 right-6  bg-gray-500 text-white p-1 absolute flex flex-col top-14">
                  <span class="self-center text-xl font-semibold whitespace-nowrap dark:text-white">
                    <Link
                      onClick={() => {
                        logout();
                        // toggleShowHideUser();
                      }}
                      className="hover:text-white"
                      to="/"
                    >
                      {" "}
                      {data.logout}
                    </Link>
                  </span>
                </div>
              ) : null}
            </span>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default AdminHeader;
