import React, { useEffect, useState } from 'react';
import MemeDisplay from './MemeDisplay';
import * as htmlToImage from 'html-to-image';
import download from 'downloadjs';
import ErrorBoundary from './ErrorBoundary';
import Headers from './Headers';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { baseURL } from '../resources/apiClient';

const MainGenerator = () => {
  const { id } = useParams();
  const [images, setImage] = useState({
    topText: '',
    bottomText: '',
    authorText: '',
    randomImg:
      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS4w6927fEU2eJT6jUoLPwgvSpLLqCVQXUHFR4-aK9mtQ&s',
    allMemeImgs: [],
  });

  useEffect(() => {
    axios
      .get(`${baseURL}meme/memes/${id}`)
      .then((response) =>
        setImage({ ...images, randomImg: response.data.imgUrl })
      )
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    fetch('https://api.imgflip.com/get_memes')
      .then((response) => response.json())
      .then((response) => {
        const { memes } = response.data;
        setImage({ ...images, allMemeImgs: memes });
      });
  }, []);

  // handle image
  const handleChange = (event) => {
    const { name, value } = event.target;
    setImage({ ...images, [name]: value });
  };

  //Add local image
  const handleUpload = (event) => {
    event.preventDefault();
    const { files } = event.target;
    const uploadFile = URL.createObjectURL(files[0]);
    setImage({ ...images, randomImg: uploadFile });
  };

  //Add random image
  const handleRandom = (event) => {
    event.preventDefault();
    const randNum = Math.floor(Math.random() * images.allMemeImgs.length);
    const randMemeImg = images.allMemeImgs[randNum].url;
    setImage({ ...images, randomImg: randMemeImg });
  };

  //Download PNG image
  const handlePng = () => {
    htmlToImage
      .toPng(document.getElementById('my-meme'))
      .then(function (dataUrl) {
        download(dataUrl, 'my-meme.png');
        console.log(dataUrl, 'base64 encoded');
      });
  };

  // Download JPEG image
  const handleJpeg = () => {
    htmlToImage
      .toJpeg(document.getElementById('my-meme'), { quality: 0.95 })
      .then(function (dataUrl) {
        var link = document.createElement('a');
        link.download = 'my-meme.jpeg';
        link.href = dataUrl;
        link.click();
      });
  };

  return (
    <div className="-mt-10">
      <ErrorBoundary>
        <MemeDisplay
          handleChange={handleChange}
          handleRandom={handleRandom}
          handlePng={handlePng}
          handleJpeg={handleJpeg}
          handleUpload={handleUpload}
          data={images}
        />
      </ErrorBoundary>
    </div>
  );
};

export default MainGenerator;
