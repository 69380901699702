import { actionTypes } from '../actions';

const watermarkReducer = (state = '', action) => {
  //Water mark text reducer
  switch (action.type) {
    case actionTypes.WATER_MARK_TEXT:
      return action.payload;
    default:
      return state;
  }
};

export { watermarkReducer };
