import React, { useState } from "react";

function FAQs() {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleClick = (idx) => {
    setSelectedTab(selectedTab === idx ? 0 : idx);
  };

  const handleRotate = (idx) => {
    return selectedTab === idx ? "rotate-180" : "";
  };

  const handleToggle = (idx) => {
    return selectedTab === idx ? { maxHeight: "500px" } : { maxHeight: "0" };
  };
  const data = [
    {
      id: 1,
      question: "What is react?",
      answer: "React is a user interface framework",
    },
    {
      id: 2,
      question: "What is tailwindcss?",
      answer: "React is a  css framework",
    },
  ];

  return (
    <main className="p-5 bg-light-blue">
      <div className="flex justify-center items-start my-2">
        <div className="w-full  my-1">
          <ul className="flex flex-col w-full">
            <li className="bg-white my-2 shadow-lg">
              <div
                onClick={() => handleClick(1)}
                className="flex flex-row justify-between  font-semibold p-3 cursor-pointer"
              >
                <span className="text-4xl">ሜም ምንድን ነው?</span>

                <svg
                  className={`fill-current text-purple-700 h-6 w-6 transform transition-transform duration-500 ${handleRotate(
                    1
                  )}`}
                  viewBox="0 0 20 20"
                >
                  <path d="M13.962,8.885l-3.736,3.739c-0.086,0.086-0.201,0.13-0.314,0.13S9.686,12.71,9.6,12.624l-3.562-3.56C5.863,8.892,5.863,8.611,6.036,8.438c0.175-0.173,0.454-0.173,0.626,0l3.25,3.247l3.426-3.424c0.173-0.172,0.451-0.172,0.624,0C14.137,8.434,14.137,8.712,13.962,8.885 M18.406,10c0,4.644-3.763,8.406-8.406,8.406S1.594,14.644,1.594,10S5.356,1.594,10,1.594S18.406,5.356,18.406,10 M17.521,10c0-4.148-3.373-7.521-7.521-7.521c-4.148,0-7.521,3.374-7.521,7.521c0,4.147,3.374,7.521,7.521,7.521C14.148,17.521,17.521,14.147,17.521,10"></path>
                </svg>
              </div>
              <div
                className="border-l-2 border-purple-600 overflow-hidden duration-500 transition-all"
                style={handleToggle(1)}
              >
                <p className="p-3 text-gray-900">
                  ሜም በፎቶ እና በፅሁፍ ቅንብር የሚገለፅ ኮሜዲ ነው። ሜሞች አስቂኝ የሆነ ሃሳብን በተለይ
                  በማህበራዊ ሚድያ ላይ በሚዘዋወር ምስል ማስረዳት ይችላሉ።
                </p>
              </div>
            </li>
          </ul>
          <ul className="flex flex-col w-full">
            <li className="bg-white my-2 shadow-lg">
              <div
                onClick={() => handleClick(2)}
                className="flex flex-row justify-between  font-semibold p-3 cursor-pointer"
              >
                <span className="text-4xl">ሜም መስራት እንዴት ይቻላል?</span>

                <svg
                  className={`fill-current text-purple-700 h-6 w-6 transform transition-transform duration-500 ${handleRotate(
                    2
                  )}`}
                  viewBox="0 0 20 20"
                >
                  <path d="M13.962,8.885l-3.736,3.739c-0.086,0.086-0.201,0.13-0.314,0.13S9.686,12.71,9.6,12.624l-3.562-3.56C5.863,8.892,5.863,8.611,6.036,8.438c0.175-0.173,0.454-0.173,0.626,0l3.25,3.247l3.426-3.424c0.173-0.172,0.451-0.172,0.624,0C14.137,8.434,14.137,8.712,13.962,8.885 M18.406,10c0,4.644-3.763,8.406-8.406,8.406S1.594,14.644,1.594,10S5.356,1.594,10,1.594S18.406,5.356,18.406,10 M17.521,10c0-4.148-3.373-7.521-7.521-7.521c-4.148,0-7.521,3.374-7.521,7.521c0,4.147,3.374,7.521,7.521,7.521C14.148,17.521,17.521,14.147,17.521,10"></path>
                </svg>
              </div>
              <div
                className="border-l-2 border-purple-600 overflow-hidden duration-500 transition-all"
                style={handleToggle(2)}
              >
                <p className="p-3 text-gray-900 text-xl">
                  ሜም ለመስራት የሚከተለውን ቅደም ተከተል ይጠቀሙ።
                </p>
              </div>
            </li>
          </ul>
          <ul className="flex flex-col w-full">
            <li className="bg-white my-2 shadow-lg">
              <div
                onClick={() => handleClick(3)}
                className="flex flex-row justify-between  font-semibold p-3 cursor-pointer"
              >
                <span className="text-4xl">ቴምፕሌት መምረጥ</span>

                <svg
                  className={`fill-current text-purple-700 h-6 w-6 transform transition-transform duration-500 ${handleRotate(
                    3
                  )}`}
                  viewBox="0 0 20 20"
                >
                  <path d="M13.962,8.885l-3.736,3.739c-0.086,0.086-0.201,0.13-0.314,0.13S9.686,12.71,9.6,12.624l-3.562-3.56C5.863,8.892,5.863,8.611,6.036,8.438c0.175-0.173,0.454-0.173,0.626,0l3.25,3.247l3.426-3.424c0.173-0.172,0.451-0.172,0.624,0C14.137,8.434,14.137,8.712,13.962,8.885 M18.406,10c0,4.644-3.763,8.406-8.406,8.406S1.594,14.644,1.594,10S5.356,1.594,10,1.594S18.406,5.356,18.406,10 M17.521,10c0-4.148-3.373-7.521-7.521-7.521c-4.148,0-7.521,3.374-7.521,7.521c0,4.147,3.374,7.521,7.521,7.521C14.148,17.521,17.521,14.147,17.521,10"></path>
                </svg>
              </div>
              <div
                className="border-l-2 border-purple-600 overflow-hidden duration-500 transition-all"
                style={handleToggle(3)}
              >
                <p className="p-3 text-gray-900 text-xl">
                  ሜም ለመስራት በመጀመሪያ ከቴምፕሌቶቹ ውስጥ ሃሳብዎን ሊገልፅ የችላል የሚሉትን ይምረጡ። ወይም
                  የራስዎን ምስል ይምረጡ የሚለውን በመጫን የራስዎን ምስል ወደ ሜም ሜከሩ ይጫኑ።
                </p>
              </div>
            </li>
          </ul>
          <ul className="flex flex-col w-full">
            <li className="bg-white my-2 shadow-lg">
              <div
                onClick={() => handleClick(4)}
                className="flex flex-row justify-between  font-semibold p-3 cursor-pointer"
              >
                <span className="text-4xl">መቀያየር</span>

                <svg
                  className={`fill-current text-purple-700 h-6 w-6 transform transition-transform duration-500 ${handleRotate(
                    4
                  )}`}
                  viewBox="0 0 20 20"
                >
                  <path d="M13.962,8.885l-3.736,3.739c-0.086,0.086-0.201,0.13-0.314,0.13S9.686,12.71,9.6,12.624l-3.562-3.56C5.863,8.892,5.863,8.611,6.036,8.438c0.175-0.173,0.454-0.173,0.626,0l3.25,3.247l3.426-3.424c0.173-0.172,0.451-0.172,0.624,0C14.137,8.434,14.137,8.712,13.962,8.885 M18.406,10c0,4.644-3.763,8.406-8.406,8.406S1.594,14.644,1.594,10S5.356,1.594,10,1.594S18.406,5.356,18.406,10 M17.521,10c0-4.148-3.373-7.521-7.521-7.521c-4.148,0-7.521,3.374-7.521,7.521c0,4.147,3.374,7.521,7.521,7.521C14.148,17.521,17.521,14.147,17.521,10"></path>
                </svg>
              </div>
              <div
                className="border-l-2 border-purple-600 overflow-hidden duration-500 transition-all"
                style={handleToggle(4)}
              >
                <p className="p-3 text-gray-900 text-xl">
                  ማስተካከያዎቹን በመጠቀም ምስሉ ላይ ፅሁፍ ይጨምሩ በሚፈልጉት መጠን ፅሁፉን ማተለቅ፣ ማሳነስ፣
                  ማጉላት ወይም ከለር መቀየር ይችላሉ። ፅሁፎቹን በሚፈልጉት ቦታ ላይ ለማኖር በምስሉ ላይ በመጎተት
                  ማንቀሳቀስ ይችላሉ። በምስሉ ላይ በቂ ቦታ ከሌለ ከላይ ወይም ከታች ተጨማሪ ቦታ መጠቀም ይችላሉ።
                </p>
              </div>
            </li>
          </ul>
          <ul className="flex flex-col w-full">
            <li className="bg-white my-2 shadow-lg">
              <div
                onClick={() => handleClick(5)}
                className="flex flex-row justify-between  font-semibold p-3 cursor-pointer"
              >
                <span className="text-4xl">ማውረድና ማጋራት?</span>

                <svg
                  className={`fill-current text-purple-700 h-6 w-6 transform transition-transform duration-500 ${handleRotate(
                    5
                  )}`}
                  viewBox="0 0 20 20"
                >
                  <path d="M13.962,8.885l-3.736,3.739c-0.086,0.086-0.201,0.13-0.314,0.13S9.686,12.71,9.6,12.624l-3.562-3.56C5.863,8.892,5.863,8.611,6.036,8.438c0.175-0.173,0.454-0.173,0.626,0l3.25,3.247l3.426-3.424c0.173-0.172,0.451-0.172,0.624,0C14.137,8.434,14.137,8.712,13.962,8.885 M18.406,10c0,4.644-3.763,8.406-8.406,8.406S1.594,14.644,1.594,10S5.356,1.594,10,1.594S18.406,5.356,18.406,10 M17.521,10c0-4.148-3.373-7.521-7.521-7.521c-4.148,0-7.521,3.374-7.521,7.521c0,4.147,3.374,7.521,7.521,7.521C14.148,17.521,17.521,14.147,17.521,10"></path>
                </svg>
              </div>
              <div
                className="border-l-2 border-purple-600 overflow-hidden duration-500 transition-all"
                style={handleToggle(5)}
              >
                <p className="p-3 text-gray-900 text-xl">
                  ምስልዎን አስተካክለው ሲጨርሱ ማውረድ (ዳውንሎድ ማድረግ) ወይም በሚፈልጉት ማህበራዊ ሚድያ ማጋራት
                  ይችላሉ።
                </p>
              </div>
            </li>
          </ul>
          <ul className="flex flex-col w-full">
            <li className="bg-white my-2 shadow-lg">
              <div
                onClick={() => handleClick(6)}
                className="flex flex-row justify-between  font-semibold p-3 cursor-pointer"
              >
                <span className="text-4xl">እንዴት ይመዘገባሉ?</span>

                <svg
                  className={`fill-current text-purple-700 h-6 w-6 transform transition-transform duration-500 ${handleRotate(
                    6
                  )}`}
                  viewBox="0 0 20 20"
                >
                  <path d="M13.962,8.885l-3.736,3.739c-0.086,0.086-0.201,0.13-0.314,0.13S9.686,12.71,9.6,12.624l-3.562-3.56C5.863,8.892,5.863,8.611,6.036,8.438c0.175-0.173,0.454-0.173,0.626,0l3.25,3.247l3.426-3.424c0.173-0.172,0.451-0.172,0.624,0C14.137,8.434,14.137,8.712,13.962,8.885 M18.406,10c0,4.644-3.763,8.406-8.406,8.406S1.594,14.644,1.594,10S5.356,1.594,10,1.594S18.406,5.356,18.406,10 M17.521,10c0-4.148-3.373-7.521-7.521-7.521c-4.148,0-7.521,3.374-7.521,7.521c0,4.147,3.374,7.521,7.521,7.521C14.148,17.521,17.521,14.147,17.521,10"></path>
                </svg>
              </div>
              <div
                className="border-l-2 border-purple-600 overflow-hidden duration-500 transition-all"
                style={handleToggle(6)}
              >
                <p className="p-3 text-gray-900 text-xl">
                  ለመመዝገብ ወደ 9860 OK ብለው ይላኩ
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </main>
  );
}

export default FAQs;
